import React, { FC } from 'react';

export interface FooterLink {
  text: string;
  id?: string;
  icon?: string;
  url?: string;
  target?: string;
}

export const Footer: FC = React.memo(() => {
  return (
    <footer className="footer">
      <div className="text-center">
        <ul>
            <li>
            &copy; 2021 NAPCON
            </li>
        </ul>
      </div>
    </footer>
  );
});

Footer.displayName = 'Footer';
